import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import qualidade from '../../assets/images/qualidade.png';
import experiencia from '../../assets/images/experiencia.png';
import compromisso from '../../assets/images/compromisso.png';
import atendimento from '../../assets/images/atendimento.png';
import './PorqueNosContratar.css';

function PorqueNosContratar() {
    const [isMobile, setIsMobile] = useState(false);
    // Define o estado isMobile baseado na largura da tela
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        // Adiciona um event listener para verificar o tamanho da tela ao redimensionar
        window.addEventListener('resize', handleResize);

        // Chama handleResize imediatamente para definir o estado inicial
        handleResize();

        // Remove o event listener ao desmontar o componente
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
     
    // Dados dos slides para o Carousel
    const slidesData = [
        /* ... dados dos slides ... */
        {
            imagePath: experiencia,
            title: ' Experiência e conhecimento',
            content: 'A empresa possui uma equipe experiente e qualificada, pronta para lidar com diversos projetos e desafios.',
        },

        {
            imagePath: qualidade,
            title: 'Qualidade e eficiência',
            content: 'Garantimos um trabalho de alta qualidade, utilizando materiais duráveis e técnicas eficientes, resultando em resultados excelentes.',
        },

        {
            imagePath: atendimento,
            title: 'Atendimento personalizado',
            content: 'Nos preocupamos em entender as necessidades específicas de cada cliente, oferecendo soluções personalizadas e um atendimento dedicado.',
        },

        {
            imagePath: compromisso,
            title: 'Pontualidade e compromisso',
            content: 'Cumprimos os  prazos estabelecidos, respeitando o tempo dos clientes e demonstrando compromisso com a entrega dos projetos.',
        },

    ];

    // Renderiza o componente de acordo com o tamanho da tela

    if (isMobile) {
        // Se for uma tela menor, renderize o slider com os dados dos slides
        return (
            < >
           
            <div  className="Container-geral " id='porque'>
                
                <h1 className="h1-titulo">Por que nos contratar?</h1>
                <p className='p-titulo' >
                 
                </p>
                <Carousel className='slider-carossel' showStatus={false} showThumbs={false} renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                            <button type="button" onClick={onClickHandler} title={label} className="control-arrow control-prev">
                                ‹
                            </button>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <button type="button" onClick={onClickHandler} title={label} className="control-arrow control-next">
                                ›
                            </button>
                        )
                    }
                >
                    {slidesData.map((slide, index) => (
                        <div className='carossel-container' key={index}>
                            <div className="circulo">
                                <img src={slide.imagePath} alt={`Imagem ${index + 1}`} />
                            </div>
                            <div className="conteudo-circulo">
                                <span className='span-circulo'>{slide.title}</span>
                                <p className='p-conteudo'>{slide.content}</p>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
            </>
        );
    }

    // Se não for uma tela menor, renderize o conteúdo sem slider
    return (
        <div className="Container-geral" id='porque'>
            <h1 className="h1-titulo">Por que nos contratar?</h1>
            <p className='p-titulo'>
                   
                   
                </p>
            <div className="circulos">
                {slidesData.map((slide, index) => (
                    <div className="container-circulo" key={index}>
                        <div className="circulo">
                            <img src={slide.imagePath} alt={`Imagem ${index + 1}`} />
                        </div>
                        <div className="conteudo-circulo">
                            <span>{slide.title}</span>
                            <p className='p-conteudo'>{slide.content}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PorqueNosContratar;









