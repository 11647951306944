import "./QuemSomos.css";
import trabalhadorCidade from "../../assets/images/trabalhador-cidade.png";

import React from "react";

export default function QuemSomos() {
  return (
    <section className="quemSomos" id="quemsomos">
      <div className="container">
        <div className="trabalhador-imagem">
          <img src={trabalhadorCidade} alt="imagem trabalhador de obras" />
        </div>
        <div className="texto-quemSomos">
          <div className="titulo-quemSomos">
            <div className="barra"></div>
            <h2>Quem somos?</h2>
          </div>

          <div className="conteudo">
            <p>
              Nós somos uma empresa especializada em construção, reformas e
              manutenção predial, comprometida em oferecer serviços de alta
              qualidade e excelência aos nossos clientes. Com uma vasta
              experiência no setor, há mais de 10 anos, construindo e
              transformando espaços com dedicação e profissionalismo.
            </p>{" "}
            <p>
              Nosso principal objetivo é superar as expectativas dos nossos
              clientes, entregando projetos personalizados e soluções eficientes
              para cada necessidade. Contamos com uma equipe qualificada de
              profissionais experientes, incluindo engenheiros, arquitetos e
              técnicos especializados, que trabalham em conjunto para garantir
              resultados duradouros e satisfatórios.
            </p>
            <p>
              Valorizamos a transparência e a honestidade em todas as etapas do
              processo. Desde o planejamento até a execução, mantemos uma
              comunicação clara e aberta com nossos clientes, mantendo-os
              informados sobre o andamento do projeto e garantindo que suas
              expectativas sejam atendidas.
            </p>
            <p>
              Além disso, priorizamos a segurança em nosso trabalho. Seguimos
              rigorosamente todas as normas técnicas e de segurança para
              garantir um ambiente seguro tanto para nossa equipe quanto para os
              que estão a nossa volta.
            </p>
            <p>
              Oferecemos uma ampla gama de serviços, incluindo construção de
              edifícios residenciais e comerciais, reformas estéticas,
              manutenção preventiva e corretiva, instalações elétricas e
              hidráulicas, entre outros. Nosso compromisso é entregar projetos
              de alta qualidade, dentro do prazo estabelecido e com um excelente
              custo-benefício.
            </p>
            <p>
              Se você está em busca de uma empresa confiável e comprometida com
              a qualidade, conte conosco. Estamos prontos para transformar suas
              ideias em realidade e tornar seu espaço ainda mais funcional,
              seguro e bonito.
            </p>
          </div>
          <div className="btn">
            <a href="#">Clique e conheça a MDR</a>
          </div>
        </div>
      </div>
    </section>
  );
}
