import React from 'react'
import instagramLogoContatos from '../../assets/images/🦆 icon _instagram_.png'
import linkedinLogoContatos from '../../assets/images/🦆 icon _call_.png'
import emailLogoContatos from '../../assets/images/🦆 icon _sms_.png'
import './Footer-contatos.css'

function FooterContatos() {
  return (
    <div>
        <h2 className='h2-contatos-mobile'>Contatos</h2>
      <div className="footer-column-contatos-mobile">
        
      <ul>
          <li> <a target='_blank' href="https://www.instagram.com/construirmdr"><img src={instagramLogoContatos} alt="" />@construirmdr </a></li>
          <li> <a target='_blank' href="tel:+5521960146960"><img src={linkedinLogoContatos} alt="" /> (21) 9-6014-6960</a></li>
          <li> <a target='_blank' href="mailto:construirmdr@gmail.com"><img src={emailLogoContatos} alt="" /> construirmdr@gmail.com</a></li>
        </ul>
      </div>
      <span className='copy-mobile'>&copy; 2023 Construir MDR. Todos os direitos reservados.</span>
    </div>
  )
}

export default FooterContatos
