import React from 'react'
import './Footer-logo.css'
import whatsappLogo from '../../assets/icons/whatsapp.svg'
import instagramLogo from '../../assets/icons/instagram.svg'
import linkedinLogo from '../../assets/icons/linkedin.svg'
import emailLogo from '../../assets/icons/email.svg'
import LogoMdrMobile from "../../assets/images/logo-mdr-footer.png"



function FooterLogo() {
  return (
    <div>
      <div className="container-logo-mobile">
        <img src={LogoMdrMobile} alt="Logo" className="logo-footer-mobile" />
        </div>
      <div className='container-logo-redes-mobile'>
        
        <div className="social-media-footer-mobile">
          <a target='_blank' href="https://wa.me/5521960146960">
            <img src={whatsappLogo} alt="Logo do whatsapp" />
          </a>
        </div>
        <div className="social-media-footer-mobile">
          <a target='_blank' href="https://www.instagram.com/construirmdr">
            <img src={instagramLogo} alt="Logo do instagram" />
          </a>
        </div>
        <div className="social-media-footer-mobile">
          <a target='_blank' href="https://www.linkedin.com">
            <img src={linkedinLogo} alt="Logo do Linkedin" />
          </a>
        </div>
        <div className="social-media-footer-mobile">
          <a target='_blank' href="mailto:mdr@construirmdr.com.br">
            <img src={emailLogo} alt="Logo de email" />
          </a>
        </div>
      </div>
      </div>
    
  )
}

export default FooterLogo
