import "./Inicio.css";
import seta from "../../assets/icons/seta.svg";

export default function Inicio() {
  return (
    <section className="inicio" id="inicio">
      <div className="image-background">
        <div className="texto-principal">
          <div className="bar"></div>
          <h1>
            Do alicerce <br /> ao telhado
          </h1>
        </div>
        <p>
          A <span>Construir MDR</span> possui as melhores <br /> soluções seja
          qual for o trabalho!
        </p>
        <div className="seta">
          <img src={seta} alt="seta" />
        </div>
      </div>
    </section>
  );
}
