import React from "react";
import Img_operario from "../../assets/images/Img_operario.png";
import "./Orcamento.css"; // Importe seu arquivo CSS aqui
import CustomCarrossel from "../portfolio/CustomCarousel";

function Orcamento() {
  return (
    <>
      {/* Parte 1: Container Geral */}
      <div className="container-geral" id="portfolio">
        <CustomCarrossel />
      </div>
      {/* Parte 2: Container do Orçamento */}
      <div className="orcamento-container" id="orcamento">
        {/* Parte 2.1: Imagem do Operário */}
        <div className="imagem-operario" id='orcamento'>
          <img src={Img_operario} alt="" />
        </div>
        {/* Parte 2.2: Conteúdo à Direita */}
        <div className="conteudo-direita" >
          <h1>
            <span className="linha-traco" ></span> Faça seu orçamento!
          </h1>
          <span className="linha-traco-mobile"></span>
          {/* ...Texto do Orçamento... */}

          <div className="conteudo-orcamento">
            <p>
              {" "}
              Na nossa empresa, entendemos a importância de fornecer orçamentos transparentes e detalhados aos nossos
              clientes. Acreditamos que a transparência é a base de um relacionamento sólido e confiável. Ao solicitar
              um orçamento conosco, você receberá uma análise minuciosa do projeto, incluindo todos os custos envolvidos,
              desde materiais até mão de obra. Nosso objetivo é garantir que você tenha total clareza sobre o investimento
              necessário para realizar sua construção, reforma ou manutenção predial.
            </p>
            <p>Além da transparência, nos preocupamos em apresentar os orçamentos de forma clara e organizada.
              Nossa equipe prepara documentos detalhados, com uma descrição completa dos serviços a serem realizados
              e prazos estimados. Acreditamos que uma apresentação profissional do orçamento é fundamental para que você
              possa tomar decisões informadas e planejar seu projeto com confiança. Estamos sempre disponíveis para
              esclarecer qualquer dúvida e ajustar o orçamento conforme suas necessidades.
            </p>
          </div>

          <button className="botao-fale-conosco">
            <a href="https://wa.me/5521960146960" target="_blank" rel="noopener noreferrer">
              Fale Conosco
            </a>
          </button>
        </div>
      </div>
    </>
  );
}

export default Orcamento;
