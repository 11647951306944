// Importando os módulos necessários do React e do react-slick
import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CustomCarousel.css';
import Imagem1 from '../../assets/images/antes.jpg'; 
import Imagem2 from '../../assets/images/depois.jpg';
import setaEsquerda from '../../assets/images/seta-esquerda.png'; 
import setaDireita from '../../assets/images/seta-direita.png';
import apartamento2 from '../../assets/imagens-portfolio/apartamento-antes.jpg';
import apartamento1 from '../../assets/imagens-portfolio/apartamento-depois.jpg';
import academia2 from '../../assets/imagens-portfolio/academia-antes.jpg';
import academia1 from '../../assets/imagens-portfolio/academia-depois.jpg';
import quadro1 from '../../assets/imagens-portfolio/quadro-antes.jpg';
import quadro2 from '../../assets/imagens-portfolio/quadro-depois.jpg';
import telhado1 from '../../assets/imagens-portfolio/telhado-antes.jpg';
import telhado2 from '../../assets/imagens-portfolio/telhado-depois.jpg';



// Definindo os itens para o carrossel principal e miniaturas
const items = [
  { id: 1, imageUrl: Imagem2, content:`Reforma de Caixa d'água, antes e depois  `, },
  { id: 2, imageUrl:quadro2 , content: 'Reforma Quadro de Incendio, antes e depois',  },
  { id: 3, imageUrl: apartamento1, content:'Reforma e decoração de apartamento, antes e depois',  },
  { id: 4, imageUrl: academia1 , content: 'Reforma e Montagem de Academia, antes e depois',  },
  { id: 5, imageUrl: telhado2, content: 'Reforma de Telhado com Impermeabilização de Calha e Beirais, antes e depois',  }
 
];

const itemsMini = [
  { id: 1, imageUrl: Imagem1, content: '' },
  { id: 2, imageUrl: quadro1, content: '' },
  { id: 3, imageUrl: apartamento2, content: '' },
  { id: 4, imageUrl: academia2, content: '' },
  { id: 5, imageUrl: telhado1, content: '' }
  
];

// Componente do Carrossel Personalizado
const CustomCarousel = () => {
  // Estados para controlar o modal
  const [modalImage, setModalImage] = useState('');
  const [showModal, setShowModal] = useState(false);

  // Função para lidar com o clique nos itens do carrossel
  const handleItemClick = (imageUrl, content) => {
    setModalImage(imageUrl);
    setShowModal(true);
  };

  // Componentes de setas personalizadas para o carrossel
  const PrevArrow = ({ onClick }) => (
    <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
      <img src={setaEsquerda} alt="Seta Esquerda" />
    </div>
  );

  const NextArrow = ({ onClick }) => (
    <div className="custom-arrow custom-next-arrow" onClick={onClick}>
      <img src={setaDireita} alt="Seta Direita" />
    </div>
  );

  // Configurações para o carrossel
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '15%',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 767, // Defina a largura de tela que deseja para a mudança na configuração
        settings: {
          centerPadding: '15%',

        }
      },
      {
        breakpoint: 768, // Tablets (768 a 1024 pixels de largura)
        settings: {
          centerPadding: '10%', // Ou qualquer outra configuração que você deseja para tablets
        }
      },
      {
        breakpoint: 492, // Telas menores (de 481 a 767 pixels de largura)
        settings: {
          centerPadding: '7%', // Ou qualquer outra configuração que você deseja para telas menores
        }
      },
    ]
  };

  // Renderização do componente
  return (
    <div className="carousel-container"  >
      <h2 className='h2-portifolio'>Portfólio</h2>
      <div className="text-portfolio">
      <p className='p-portifolio'>
        Bem-vindo ao nosso portfólio! Somos uma empresa dedicada à construção,
        
         reforma e manutenção predial, oferecendo soluções completas e de
         
          qualidade para atender todas as necessidades dos nossos clientes. Navegue pelo nosso portfólio e descubra os projetos incríveis que já
         realizamos, desde construções residenciais e comerciais
        até reformas personalizadas. Nosso compromisso com a excelência,
        a eficiência e o atendimento personalizado garantem resultados impecáveis em cada projeto.
         Estamos ansiosos para fazer parte do seu próximo projeto de sucesso!"
      </p>
      </div>
      {/* Carrossel principal */}
      <Slider {...settings}>
        {items.map((item, index) => (
          <div className="img-carousel" key={item.id}>
            {/* Imagem principal com miniatura */}

            <img className='main-img' src={item.imageUrl} alt={item.content} onClick={() => handleItemClick(item.imageUrl, item.content)} />
            <div className='thumbnail-img'>
              <img className='thumbnail' src={itemsMini[index].imageUrl} alt="" onClick={() => handleItemClick(itemsMini[index].imageUrl, item.content)} />
              <span className='image-text' onClick={() => handleItemClick(itemsMini[index].imageUrl, item.content)}>Antes</span>
            </div>
            <div className="img-text">
            <p>{item.content}</p>
            <span className='date'>{item.date}</span>
          </div>
          </div>
        ))}
      </Slider>

      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <img src={modalImage} alt="Imagem do Modal" />
          </div>
        </div>
      )}
    </div>
  );
};

// Exportando o componente para ser usado em outros lugares
export default CustomCarousel;
