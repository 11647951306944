import React from 'react';
import './Footer.css'; // Importe seu arquivo CSS aqu
import LogoMdr from "../../assets/images/logo-mdr-footer.png"
import LogoMdrMobile from "../../assets/images/mdr-logo-mobile.png"
import whatsappLogo from '../../assets/icons/whatsapp.svg'
import instagramLogo from '../../assets/icons/instagram.svg'
import linkedinLogo from '../../assets/icons/linkedin.svg'
import emailLogo from '../../assets/icons/email.svg'

import instagramLogoContatos from '../../assets/images/🦆 icon _instagram_.png'
import linkedinLogoContatos from '../../assets/images/🦆 icon _call_.png'
import emailLogoContatos from '../../assets/images/🦆 icon _sms_.png'


function Footer() {
  return (
    <>
   
    <footer className="footer-container">
      <div className='geral'>
      <div className="container-logo">
        <img src={LogoMdr} alt="Logo" className="logo-footer" />
        </div>
      <div className='container-logo-redes'>
        
        <div className="social-media-footer">
          <a target='_blank' href="https://wa.me/5521970095768">
            <img src={whatsappLogo} alt="Logo do whatsapp" />
          </a>
        </div>
        <div className="social-media-footer">
          <a target='_blank'  href="https://www.instagram.com/construirmdr">
            <img src={instagramLogo} alt="Logo do instagram" />
          </a>
        </div>
        <div className="social-media-footer">
          <a target='_blank' href="https://www.linkedin.com">
            <img src={linkedinLogo} alt="Logo do Linkedin" />
          </a>
        </div>
        <div className="social-media-footer">
          <a target='_blank' href="mailto:construirmdr@gmail.com">
            <img src={emailLogo} alt="Logo de email" />
          </a>
        </div>
      </div>
      </div>
      <div className="footer-column">
        <h2>Nossos serviços</h2>
        <ul>
          <li>• Reforma de fachada</li>
          <li>• Troca de Tubulações</li>
          <li>• Reforma de Caixas D'águas e Cisternas</li>
          <li>• Reformas em geral</li>
          <li>• Reforma de elétrica</li>
        </ul>
      </div>
      <div className="footer-column2">
        <h2></h2>
        <ul>
          <li>• Pintura</li>
          <li>• Alvenaria</li>
          <li>• Colocação de revestimentos</li>
          <li>• Vistoria de fachada com drone</li>
          <li>• Marcenaria e carpintaria</li>
        </ul>
      </div>
      <div className="footer-column-contatos">
        <h2>Contatos</h2>
        <ul>
          <li> <a target='_blank' href="https://www.instagram.com/construirmdr"><img src={instagramLogoContatos} alt="" />@construirmdr </a></li>
          <li> <a target='_blank' href="tel:+5521960146960"><img src={linkedinLogoContatos} alt="" /> (21) 9-6014-6960</a></li>
          <li> <a target='_blank' href="mailto:mdr@construirmdr.com.br"><img src={emailLogoContatos} alt="" /> construirmdr@gmail.com</a></li>
        </ul>
      </div>
    </footer>
    <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=x83D9zm9Bk5d94hvM9Rz43k74Y3P2V1oDNHh0J7u8QwKARmzNvli4E49R5SD"></script></span>
    <span className='copy'>&copy; 2023 Contruir MDR. Todos os direitos reservados.</span>
    </>
  );
}

export default Footer;



/* 

 <div className="footer-column">
        <h2>Nossoa serviços</h2>
        <ul>
          <li>• Lorem Ipsum</li>
          <li>• Lorem Ipsum</li>
          <li>• Lorem Ipsum</li>
        </ul>
      </div>
      <div className="footer-column">
        <h2></h2>
        <ul>
          <li>• Lorem Ipsum1</li>
          <li>• Lorem Ipsum</li>
          <li>• Lorem Ipsum</li>
        </ul>
      </div>
      <div className="footer-column">
        <h2>Contatos</h2>
        <ul>
          <li>• Lorem Ipsum</li>
          <li>• Lorem Ipsum</li>
          <li>• Lorem Ipsum</li>
        </ul>
      </div>









<div className="footer-column">
        <div className="logo-container">
          <div className="logo-column">
            <img src={LogoMdr} alt="Logo" className="logo" />
            <img src={LogoMdrMobile} alt="Logo" className="logo-footer-mobile" />
          </div>
          <div className="social-media">
            <a href="#">
              <img src={whatsappLogo} alt="Logo do whatsapp" />
            </a>
           </div>
           <div className="social-media">
            <a href="#">
              <img src={instagramLogo} alt="Logo do instagram" />
            </a>
           </div>
           <div className="social-media">
            <a href="#">
              <img src={linkedinLogo} alt="Logo do Linkedin" />
            </a>
           </div>
           <div className="social-media">
            <a href="#">
              <img src={emailLogo} alt="Logo de email" />
            </a>
           </div>
            </div>
        <div className="footer-column">
          <h2>Nossoa serviços</h2>
          <ul>
            <li>• Lorem Ipsum</li>
            <li>• Lorem Ipsum</li>
            <li>• Lorem Ipsum</li>
          </ul>
          </div>
          <div className="footer-column">
          <h2></h2>
          <ul>
            <li>• Lorem Ipsum1</li>
            <li>• Lorem Ipsum</li>
            <li>• Lorem Ipsum</li>
          </ul>
          </div>
          <div className="footer-column">
          <h2>Contatos</h2>
          <ul>
            <li>• Lorem Ipsum</li>
            <li>• Lorem Ipsum</li>
            <li>• Lorem Ipsum</li>
          </ul>
          </div>
          </div>*/ 