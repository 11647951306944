
import React from 'react'
import FooterLista from './Footer-lista';
import FooterLogo from './Footer-logo'
import FooterContatos from './Footer-contatos';

function FooterMobile() {
  return (
    <>
    <div>
      <FooterLogo/>
      <FooterLista/>
      <FooterContatos/>
    </div>
    </>
    
  )
}

export default FooterMobile;
