

import './App.css';
import Header from './components/Header';
import Inicio from './pages/Inicio';
import QuemSomos from './pages/QuemSomos';
import PorqueNosContratar from './pages/PorqueNosContratar/PorqueNosContratar';
import Orcamento from './pages/Orcamento-Carossel/Orcamento';
import FooterPrincipal from './pages/Footer/Footer';
import FooterMobile from './pages/Footer/Footer-mobile';

function App() {

  return (
    <div className="App">
      <Header />
      <main>
        <Inicio />
        <QuemSomos/>
        <PorqueNosContratar/>
        <Orcamento/>
      </main>
      <footer>
        <FooterPrincipal/>
        <FooterMobile />
      </footer>
    </div>
  );
}

export default App;






