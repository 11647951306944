import "./Header.css";
import Logo from "../../assets/images/header-logo.png";
import whatsappLogo from "../../assets/icons/whatsapp.svg";
import instagramLogo from "../../assets/icons/instagram.svg";
import linkedinLogo from "../../assets/icons/linkedin.svg";
import emailLogo from "../../assets/icons/email.svg";

export default function Header() {
  function abrirMenu() {
    const menu = document.querySelector(".menu");
    menu.classList.toggle("ativar");
    const menuJanela = document.querySelector(".mobile-menu");
    menuJanela.classList.toggle("show");
  }

  return (
    <header>
      <div className="nav-bar">
        <div className="logo">
          <img src={Logo} alt="Logo da MDR" />
        </div>
        <nav>
          <ul className="links-site">
            <li className="link">
              <a href="#inicio">Início</a>
            </li>
            <li className="link">
              <a href="#quemsomos">Quem Somos?</a>
            </li>
            <li className="link">
              <a href="#porque">Porque nos contratar?</a>
            </li>
            <li className="link">
              <a href="#portfolio">Portfólio</a>
            </li>
            <li className="link">
              <a href="#orcamento">Orçamento</a>
            </li>
          </ul>
          <div className="links-socialMedia">
            <div className="social-media">
            <a target="_blank" href="https://wa.me/5521960146960">
                <img src={whatsappLogo} alt="Logo do whatsapp" />
              </a>
            </div>
            <div className="social-media">
            <a target="_blank" href="https://www.instagram.com/construirmdr">
                <img src={instagramLogo} alt="Logo do instagram" />
              </a>
            </div>
            <div className="social-media">
            <a target="_blank" href="https://www.linkedin.com">
                <img src={linkedinLogo} alt="Logo do Linkedin" />
              </a>
            </div>
            <div className="social-media">
            <a target="_blank" href="mailto:construirmdr@gmail.com">
                <img src={emailLogo} alt="Logo de email" />
              </a>
            </div>
          </div>
          <div onClick={abrirMenu} className="menu">
            <span className="linha"></span>
            <span className="linha"></span>
            <span className="linha"></span>
          </div>
        </nav>
      </div>
      <div onClick={abrirMenu} className="mobile-menu">
          <ul className="mobile-menu-links">
            <li className="link">
              <div className="bar"></div>
              <a href="#inicio">Início</a>
            </li>
            <li className="link">
              <div className="bar"></div>
              <a href="#quemsomos">Quem Somos?</a>
            </li>
            <li className="link">
              <div className="bar"></div>
              <a href="#porque">Porque nos contratar?</a>
            </li>
            <li className="link">
              <div className="bar"></div>
              <a href="#portfolio">Portfólio</a>
            </li>
            <li className="link">
              <div className="bar"></div>
              <a href="#orcamento">Orçamento</a>
            </li>
          </ul>
          <div className="mobile-menu-socialMedia">
            <div className="social-media">
            <a target="_blank" href="https://wa.me/5521960146960">
                <img src={whatsappLogo} alt="Logo do whatsapp" />
              </a>
            </div>
            <div className="social-media">
            <a target="_blank" href="https://www.instagram.com/construirmdr">
                <img src={instagramLogo} alt="Logo do instagram" />
              </a>
            </div>
            <div className="social-media">
            <a target="_blank" href="https://www.linkedin.com">
                <img src={linkedinLogo} alt="Logo do Linkedin" />
              </a>
            </div>
            <div className="social-media">
            <a target="_blank" href="mailto:construirmdr@gmail.com">
                <img src={emailLogo} alt="Logo de email" />
              </a>
            </div>
          </div>
        </div>
    </header>
  );
}
