import React from 'react'
import './Footer-lista.css'

function FooterLista() {
    return (
        <div>
            <h2 className='h2-lista'>Nossos serviços</h2>
            <div className="container-mobile-colunm">

                <div className="footer-mobile-column1">

                    <ul>
                        <li>• Reforma de fachada</li>
                        <li>• Troca de Tubulações</li>
                        <li>• Reforma de Caixas <br /> D'águas e Cisternas</li>
                        <li>• Reformas em geral</li>
                        <li>• Reforma de elétrica</li>
                        <li>• Pintura</li>
                        <li>• Alvenaria</li>
                        <li>• Colocação de revestimentos</li>
                        <li>• Vistoria de fachada com drone</li>
                        <li>• Marcenaria e carpintaria</li>
                    </ul>
                </div>
                 {/* Parte 2: Container do Orçamento 
                <div className="footer-mobile-column2">
                    <h2></h2>
                    <ul>
                       
                    </ul>
                </div>*/}
            </div>

        </div>
    )
}

export default FooterLista;